import {DownOutlined} from "@ant-design/icons";
import {Dropdown, MenuProps, Space, Typography} from "antd";
import {useState} from "react";
import {toast} from "react-toastify";

import {ExcelIcon} from "src/assets/icons";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import {useFilter} from "src/hooks/useFilter";
import {useAppDispatch} from "src/redux/hooks";
import {EnumGoalReport, handleExportExcel} from "src/services/aims.services";

export const ExportExcel = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {filter} = useFilter({});

  const onExportExcel = (type: EnumGoalReport) => {
    setLoading(true);
    dispatch(
      handleExportExcel({
        ...filter,
        excel_template: type
      })
    )
      .unwrap()
      .then(res => {
        setOpen(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'goals_report.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        setOpen(false);
        toast.error("Có lỗi xảy ra. Vui lòng thử lại sau.", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      })
      .finally(() => setLoading(false));
  };

  const ExcelTemplateName: Record<EnumGoalReport, string> = {
    [EnumGoalReport.STATISTIC]: "Báo cáo theo thống kê mục tiêu con",
    [EnumGoalReport.PHASE]: "Báo cáo theo giai đoạn",
    [EnumGoalReport.DETAIL]: "Báo cáo theo nội dung mục tiêu con"
  };
  const items: MenuProps["items"] = [
    EnumGoalReport.STATISTIC,
    EnumGoalReport.DETAIL,
    EnumGoalReport.PHASE
  ].map(key => ({
    key,
    label: ExcelTemplateName[key]
  }));
  return (
    <>
      <Dropdown
        menu={{
          onClick: e => onExportExcel(e.key as EnumGoalReport),
          items
        }}>
        <Button
          key="export"
          style={{display: "flex", alignItems: "center", gap: "8px"}}
          onClick={e => e.preventDefault()}
          loading={loading}>
          <ExcelIcon />
          Xuất file Excel
        </Button>
      </Dropdown>

      <Modal
        open={open}
        modalType="confirm"
        title="Xuất file excel"
        okText="Đồng ý"
        cancelText="Hủy"
        onOk={onExportExcel}
        onCancel={() => {
          setOpen(false);
        }}>
        <Typography style={{textAlign: "center"}}>Bạn có đồng ý xuất file không?</Typography>
      </Modal>
    </>
  );
};
